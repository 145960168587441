<template>
  <div style="width:100%">
    <el-form :inline="true" :model="form">
      <el-form-item label="状态：">
        <el-select v-model="form.search_status" placeholder="请选择状态" style="width: 206px">
          <el-option :label="e.name" :value="e.id" v-for="(e, i) in statusList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文章标题：">
        <el-input v-model="form.search_name" placeholder="请输入文章标题" style="width: 206px" clearable></el-input>
      </el-form-item>
      <el-form-item label="文章分类：">
        <el-select v-model="form.search_type" placeholder="请选择文章分类" style="width: 206px">
          <el-option :label="e.name" :value="e.id" v-for="(e, i) in typeList" :key="i"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="btn_item">
        <el-button type="primary" @click="onSearch">查询</el-button>
        <el-button @click="onReset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="patch_del cursor" @click="onPatchRemove">批量删除</div>
    <!-- 表格 -->
    <div class="tableClass">
      <el-table :data="tableList" ref="listTable" :header-cell-style="{
          fontWeight: '600',
          fontSize: '15px',
          background: '#F7F7F7',
          color: '#333',
        }" @selection-change="selectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" width="50" label="序号">
        </el-table-column>
        <el-table-column prop="name" align="center" label="文章标题" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="type_name" align="center" label="文章分类">
        </el-table-column>
        <el-table-column prop="teaching_studio_name" align="center" label="所属工作室">
        </el-table-column>
        <el-table-column prop="TeachingStudioArticleReply_count" align="center" label="评论数">
        </el-table-column>
        <el-table-column prop="selected_count" align="center" label="状态">
          <template slot-scope="{ row }">
            <div>
              <span>{{ row.status_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="{ row }">
            <div>
              <el-button type="text" @click="onGoDetail(row.id,row)">查看</el-button>
              <el-button type="text" class="del" @click="onRemove(row.id)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div v-show="total" class="pagina_div" style="height: 60px;">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
        :page-sizes="[10,20,30,40,50]" :page-size="form.limit" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { ArticleList, ArticleCategoryList, delArticle, } from "@/api/teacherStudio";
export default {
  data() {
    return {
      form: {
        user_id: localStorage.getItem("id"),
        page: 1,
        limit: 10,
        search_status: "",
        search_type: "",
        search_name: "",
      },
      statusList: [
        { name: "全部", id: 0 },
        { name: "审核中", id: 1 },
        { name: "展示中", id: 2 },
        { name: "四名推荐中", id: 3 },
        { name: "不通过", id: 4 },
      ],
      select_id: [],
      tableList: [],
      typeList: [],
      total: 0,
    };
  },
  methods: {
    // 批量删除
    onPatchRemove() {
      if (!this.select_id.length) {
        return this.$message.warning("请选择再删除!");
      }
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.onDelApi,
        id: this.select_id,
      });
    },
    // 删除
    onRemove(id) {
      this.myConfirm({
        content: `确定要删除当前数据吗？`,
        fn: this.onDelApi,
        id: [id],
      });
    },
    // 删除接口
    onDelApi(id) {
      console.log(id, "idd");
      delArticle({ ids: id }).then((res) => {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
        });
        this.onGetList();
      });
    },
    // 查看
    onGoDetail(tid, item) {
      localStorage.setItem("studioId", item.teaching_studio_id);
      this.$router.push({
        path: "/ArticleAudit",
        query: {
          tid,
        },
      });
    },
    //查询
    onSearch() {
      this.onGetList();
    },
    //重置
    onReset() {
      this.form = this.$options.data().form;
      this.onGetList();
    },
    onGetList() {
      ArticleList(this.form).then((res) => {
        this.tableList = res.data.data;
        this.total = res.data.total;
      });
    },
    selectionChange(selection) {
      this.select_id = selection.map(e => e.id);
    },
    handleSizeChange(val) {
      this.$set(this.form, "page", 1);
      this.$set(this.form, "limit", val);
      this.onGetList();
    },
    handleCurrentChange(val) {
      this.$set(this.form, "page", val);
      this.onGetList();
    },
  },
  created() {
    // 文章分类列表
    ArticleCategoryList({
      search_name: "",
      user_id: localStorage.getItem("id"),
    }).then((res) => {
      this.typeList = res.data;
    });
    this.onGetList();
  },
};
</script>

<style lang="less" scoped>
.tableClass {
  padding: 20px 0 0px;
  box-sizing: border-box;
}
/deep/ .el-form {
  .el-form-item {
    margin-right: 30px;
  }
  .el-input__inner {
    height: 34px;
  }
  .el-form-item__label {
    padding: 0;
  }
  .el-button {
    padding: 9px 19px;
  }
  .btn_item {
    margin-right: 0;
  }
}
/deep/ .del {
  &:hover {
    span {
      color: #ff0607;
    }
  }
}
.patch_del {
  width: 102px;
  height: 34px;
  border: 1px solid #3489ff;
  border-radius: 4px;
  text-align: center;
  line-height: 34px;
  color: #3489ff;
}
</style>