<template>
  <div class="public_css_module personal_right_box message_page">
    <div class="personal_top_header">
      <div class="leftResearch">
        <div class="page_title">我的文章</div>
      </div>
    </div>

    <div class="main">
      <TeacherArticle></TeacherArticle>
    </div>
  </div>
</template>

<script>
import TeacherArticle from "./TeacherArticle.vue";
export default {
  data() {
    return {};
  },
  components: { TeacherArticle },
  created() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.message_page {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
}
//主要内容部分
.main {
  padding: 38px 32px;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 0;
}
</style>
